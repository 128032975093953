html,
body {
  margin: 0;
  padding: 0;

  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  color: #333;
}

a {
  color: black;
  font-weight: bold;
}

code,
pre {
  font-family: Menlo, Consolas, monospace;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  color: black;
}

.App {
  padding: 24px;
  margin-bottom: 160px;
}

.Editor {
  margin: 16px -24px;
}

.Code {
  white-space: pre !important;
  overflow: hidden;

  outline: none;
}

.InfoPanelGroup {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -12px;

  .InfoPanel {
    box-sizing: border-box;
    padding: 12px;
    flex: 33.333% 1 1;
  }
}

h2 {
  margin: 0 0 24px 0;
}

// PRISM THEME

code[class*="language-"],
pre[class*="language-"] {
  color: #444;

  white-space: pre;
  overflow: hidden;
}

pre > code[class*="language-"] {
  font-size: 1em;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  background: #b3d4fc;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  border: 1px solid #dddddd;
  background-color: white;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.2em;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #f8f8f8;
  border: 1px solid #dddddd;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #999988;
  font-style: italic;
}

.token.namespace {
  opacity: 0.7;
}

.token.string,
.token.attr-value {
  color: #444;
  font-style: italic;
}

.token.punctuation,
.token.operator {
  color: #777;
}

.token.entity,
.token.url,
.token.symbol,
.token.number,
.token.boolean,
.token.variable,
.token.constant,
.token.property,
.token.regex,
.token.inserted {
  font-weight: bold;
  color: black;
}

.token.atrule,
.token.keyword,
.token.attr-name,
.language-autohotkey .token.selector {
  font-weight: bold;
  text-decoration: underline;
  /* font-style: italic; */
}

.token.function,
.token.deleted,
.language-autohotkey .token.tag {
  /* color: #9a050f; */
}

.token.important,
.token.function,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}
